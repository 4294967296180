import { required } from '@vuelidate/validators'

const Config = {
  datatableFeatures: {
    buttons: {
      createButton: false,
      createButtonRoute: 'administration-custom-plans-new',
      refresh: true,
      customButton: true,
      customButtonLabel: 'Create Custom Plan',
      customButtonActionType: 'createReferral',
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'subscription_plan_id', text: 'Plan ID' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Custom Plan',
        view: 'View Custom Plan',
        remove: 'Remove Custom Plan',
        create: 'Create New Custom Plan',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'subscription_plan_name', label: 'Plan Name', sortable: false, visible: true, type: 'string',
        },
        {
          key: 'referral_key', label: 'Referral Key', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'subscription_plan_id', label: 'Plan ID', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'is_referral', label: 'Is Referral', sortable: true, visible: false, type: 'boolFlag',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, class: 'text-right', type: 'date',
        },
        // {
        //   key: 'actions',
        //   label: 'Action',
        //   class: 'text-right',
        //   visible: true,
        // },
      ],
    }
  },
}

export default Config
