<template>
  <div>
    <leanpay-datatable-component
      ref="referralTable"
      title="Custom Plans"
      store-module="referral"
      store-data-endpoint="getAdminReferralsList"
      store-getter-endpoint="referralList"
      pagination-state-key="referralList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="subscription_plan_id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @custom-button-action="createReferralClicked"
    />

    <b-modal
      ref="CreateReferralModal"
      hide-footer
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Create Custom Plan"
    >
      <b-form-group
        class="mt-1"
        :class="[{ 'has-error': v$.createReferralForm.subscription_plan_id.$error }]"
      >
        <label>Subscription Plan</label>
        <v-select
          v-model="createReferralForm.subscription_plan_id"
          :reduce="templateListNames => templateListNames.id"
          :class="[{'select-size-sm': fieldSize === 'sm'},{'select-size-lg': fieldSize === 'lg'}, {'select-size-md': fieldSize === 'md'}]"
          label="name"
          placeholder="Please select"
          :options="templateListNames"
        />
        <template v-for="(error, idx) in v$.createReferralForm.subscription_plan_id.$errors">
          <p
            :key="'error-'+idx"
            class="small text-danger"
          >
            {{ error.$message }}
          </p>
        </template>
      </b-form-group>
      <b-form-group class="mt-1">
        <!--        <b-form-group>-->
        <!--          <b-form-checkbox-->
        <!--            v-model="createReferralForm.is_referral"-->
        <!--            checked="false"-->
        <!--            switch-->
        <!--            inline-->
        <!--          >-->
        <!--            This is a referral-->
        <!--          </b-form-checkbox>-->
        <!--        </b-form-group>-->
        <template v-for="(error, idx) in v$.createReferralForm.is_referral.$errors">
          <p
            :key="'error-'+idx"
            class="small text-danger"
          >
            {{ error.$message }}
          </p>
        </template>
      </b-form-group>

      <div class="d-flex justify-content-end">

        <b-button
          class="mr-1"
          type="reset"
          variant="secondary"
          @click="cancelForm"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="onSubmit"
        >
          Submit
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import {
  required,
} from '@vuelidate/validators'
import Config from '@/views/administration/custom-plans/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import leanpayDatatableComponent from '@/leanpay-components/LeanpayDatatableComponent.vue'
import uploadForm from '@/views/admin/billUpload/Form.vue'

export default {
  components: { uploadForm, leanpayDatatableComponent, vSelect },
  mixins: [humaniseMixin],

  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      createReferralForm: {
        subscription_plan_id: { required },
        is_referral: { required },
      },
    }
  },
  data() {
    return {
      cardTitle: 'Referral',
      dataGridEndpoint: [],
      module: 'referral',
      fieldSize: 'sm',

      createReferralForm: {
        subscription_plan_id: null,
        is_referral: false,
      },

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      searchFieldOpts: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'invoice_status', text: 'Invoice Status' },
      ],
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    templateListNames() {
      const list = this.$store.getters['subscriptionsPlan/getSubscriptionPlanList']
      const array = []
      list.forEach(item => array.push({ id: item.id, name: item.plan_name }))
      return array
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Custom Plans' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    // this.initDataGrid()
  },
  methods: {
    resetForm() {
      this.createReferalForm = {
        subscription_plan_id: null,
        is_referral: false,
      }
    },
    cancelForm() {
      this.resetForm()
      this.$refs.CreateReferralModal.hide()
    },
    createReferralClicked(e) {
      if (e === 'createReferral') {
        this.fetchSubscriptionPlan()
        this.$refs.CreateReferralModal.show()
      }
    },
    async onSubmit() {
      const valid = await this.v$.createReferralForm.$validate()

      if (!valid) {
        return
      }

      const payload = {
        ...this.createReferralForm,
      }
      await this.$store.dispatch('referral/createReferral', payload).then(data => {
        if (data.data.response_code === 2000) {
          this.cancelForm()
          this.$refs.referralTable.initDataGrid()
        }
      })
    },
    fetchSubscriptionPlan() {
      const payload = {
        limit: 10,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
      }
      this.$store.dispatch('subscriptionsPlan/getSubscriptions', payload)
    },

  },
}
</script>
<style lang="scss">
.has-error {
  .vs__dropdown-toggle {
    border: 1px solid #ED4C67;

  }
}
</style>
